function getEnvironmentVariable(name: string): string {
    const value = process.env[name.toUpperCase()];
    return value ? value : '';
}

function capitaliseFirstLetter(value: string) {
    return value.substring(0, 1).toUpperCase() + value.substring(1, value.length);
}

function toNormalizedLowerCase(value: string) {
    return value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

function getResourceURI(resource: 'storageBlob' | 'storageTable') {
    const storageAccountName = getEnvironmentVariable('REACT_APP_AZURE_STORAGE_ACCOUNT');
    switch (resource) {
        case 'storageBlob':
            return `https://${storageAccountName}.blob.core.windows.net`;
        case 'storageTable':
            return `https://${storageAccountName}.table.core.windows.net/`;
        default:
            return `https://${storageAccountName}.blob.core.windows.net`;
    }
}

function getFileFormat(fileType: string) {
    switch (fileType) {
        case 'application/pdf':
            return 'pdf';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xlsx';
        case 'text/csv':
            return 'csv';
        case 'application/vnd.ms-excel':
            return 'xls';
        default:
            return 'pdf';
    }
}

function checkFileNameMatchesPattern(fileName: string) {
    const pattern =
        '^([a-zA-Z0-9 ]+)-(\\d{2})-(\\d{2})-([a-zA-Z]+)-loss runs-([a-zA-Z0-9 ]+)-VD-(\\d{2})\\.(\\d{2})\\.(\\d{2})\\.([a-zA-Z]+)$';
    const match = fileName.match(pattern);

    if (match) {
        const [_, accountName, startYear, endYear, lob, carrierName, month, day, year] =
            match;
        return {
            _,
            accountName,
            startYear,
            endYear,
            lob,
            carrierName,
            month,
            day,
            year,
        };
    }
    return null;
}

const getInitials = (fullName: string) => {
    return fullName
        .split(' ')
        .map((name) => {
            return name.charAt(0).toUpperCase();
        })
        .slice(0, 2)
        .join('');
};

const captializeText = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const calculateSuccessPercentage = (totalFiles: number, totalSucess: number) => {
    if (totalSucess !== 0 && totalFiles !== 0) {
        return `${Math.ceil((totalSucess / totalFiles) * 100)}%`;
    }
    return '0%';
};

const getFieldName = (name: string) => {
    return name
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace(/^./, (char) => {
            return char.toUpperCase();
        });
};

const convertSnakeCaseToSentenceCase = (snakeCase: string) => {
    return snakeCase
        .split('_')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
};

const convertSnakeCasetoPascalCase = (str: string) => {
    return str.replace(/(^\w|_\w)/g, (match) => {
        return match.replace('_', '').toUpperCase();
    });
};

const getCurrentMonth = () => {
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
    return { value: month, label: month };
};

const getCurrentYear = () => {
    return new Date().getFullYear();
};

const getYearOptions = (rangePrevNext?: number) => {
    return Array.from({ length: rangePrevNext ? rangePrevNext : 21 }, (_, i) => {
        const year = getCurrentYear() - 10 + i;
        return {
            value: year.toString(),
            label: year.toString(),
        };
    });
};

const getMonthOptions = () => {
    return Array.from({ length: 12 }, (_, i) => {
        const month = (i + 1).toString().padStart(2, '0');
        return {
            value: month,
            label: month,
        };
    });
};

const formatFileSize = (sizeInKB: string) => {
    const size = parseFloat(sizeInKB);
    if (isNaN(size)) {
        return 'Invalid size';
    }

    const k = 1024;
    if (size < k) {
        return `${size} Bytes`;
    } else if (size < k * k) {
        return `${Math.round(size / k)} KB`;
    }
    return `${Math.round(size / (k * k))} MB`;
};

export {
    getEnvironmentVariable,
    capitaliseFirstLetter,
    getResourceURI,
    getFileFormat,
    toNormalizedLowerCase,
    checkFileNameMatchesPattern,
    getInitials,
    captializeText,
    calculateSuccessPercentage,
    getFieldName,
    convertSnakeCaseToSentenceCase,
    convertSnakeCasetoPascalCase,
    getCurrentMonth,
    getCurrentYear,
    getYearOptions,
    getMonthOptions,
    formatFileSize,
};
