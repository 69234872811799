import '../styles/userAvatar.scss';
import React from 'react';
import classNames from 'classnames';
import { getInitials } from '../helper/index';

interface userAvatarProps {
    name: string;
    email?: string;
    hideAvatarDetail?: boolean;
    customClassname?: string;
}

function UserAvatar(props: userAvatarProps) {
    const { name, email, hideAvatarDetail, customClassname } = props;
    return (
        <div
            className={classNames(
                'user-avatar-container',
                customClassname ? customClassname : '',
            )}
        >
            <div className="user-avatar">{name ? getInitials(name) : 'ZD'}</div>
            {!hideAvatarDetail && (
                <div className="user-avatar-detail-container">
                    <span className="user-avatar-name">{name}</span>
                    <span className="user-avatar-email">{email}</span>
                </div>
            )}
        </div>
    );
}

export default UserAvatar;
