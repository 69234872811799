import '../styles/mainLoader.scss';
import { ReactComponent as Loader } from '../images/main-loader.svg';
import React from 'react';

function MainLoader(): React.JSX.Element {
    return (
        <div className="app-loader-container">
            <Loader />
        </div>
    );
}

export default MainLoader;
