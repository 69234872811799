import NavigationTab from '../../tab/NavigationTab';
import React from 'react';
import { postprocessingTabItem } from '../../tab/TabItems';

function Postprocessing() {
    return (
        <div className="postprocessing-container">
            <NavigationTab
                tab="settings"
                tabItems={postprocessingTabItem}
                variant="secondary"
            />
        </div>
    );
}

export default Postprocessing;
