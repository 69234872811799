import React from 'react';
import Skeleton from './Skeleton';

function DashboardSkeleton() {
    return (
        <div className="p-4">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-4 align-items-center">
                    <Skeleton width="140px" inline />
                    <Skeleton width="110px" height="26px" inline />
                    <Skeleton width="70px" inline />
                    <Skeleton width="60px" height="26px" inline />
                </div>
                <div className="d-flex gap-2 align-items-center">
                    <Skeleton width="36px" height="30px" inline />
                    <Skeleton width="26px" height="26px" inline circle />
                </div>
            </div>
            <Skeleton
                height="50px"
                count={4}
                inline
                containerClassName="d-flex gap-2 align-items-center my-4"
            />
            <Skeleton
                height="270px"
                width="50%"
                count={2}
                containerClassName="d-flex gap-2"
                inline
            />
            <Skeleton
                height="270px"
                width="50%"
                count={2}
                containerClassName="d-flex gap-2 mt-2"
                inline
            />
        </div>
    );
}

export default DashboardSkeleton;
