import './skeleton.scss';
import React, { CSSProperties } from 'react';

export interface gzskeletonStyleProps {
    height?: string | number;
    width?: string | number;
    borderRadius?: string | number;
    inline?: boolean;
}

export interface gzskeletonProps extends gzskeletonStyleProps {
    count?: number;
    className?: string;
    containerClassName?: string;
    circle?: boolean;
    style?: CSSProperties;
}

/* Convert style props to css properties */
function convertStylePropsToCssProperties({
    height,
    width,
    circle,
    borderRadius,
}: gzskeletonStyleProps & { circle: boolean }): CSSProperties {
    const style: CSSProperties & Record<`--${string}`, string> = {};

    if (typeof height === 'string' || typeof height === 'number') {
        style.height = height;
    }
    if (typeof width === 'string' || typeof width === 'number') {
        style.width = width;
    }
    if (typeof borderRadius === 'string' || typeof borderRadius === 'number') {
        style.borderRadius = borderRadius;
    }

    if (circle) {
        style.borderRadius = '50%';
    }

    return style;
}

function Skeleton({
    count = 1,
    className: customClassName,
    containerClassName,
    circle = false,
    style: styleProp,
    ...originalGzSkeletonStyleProps
}: gzskeletonProps): React.JSX.Element {
    const propStyleOptions = { ...originalGzSkeletonStyleProps };
    const style = {
        ...styleProp,
        ...convertStylePropsToCssProperties({ ...propStyleOptions, circle }),
    };

    /* Set base class name and customClassName if any */
    let className = 'gz-skeleton-loader';
    if (customClassName) {
        className += ` ${customClassName}`;
    }

    /* Eliminate undefined or null */
    const inline = propStyleOptions.inline ?? false;

    const skeletons: React.ReactElement[] = [];

    const countCeil = Math.ceil(count);

    for (let c = 0; c < countCeil; c++) {
        let currentStyle = style;

        /* Skeleton for fraction part if any eg: if count is 1.5, skeleton width for .5 needs to calculated */
        if (countCeil > count && c === countCeil - 1) {
            const width = currentStyle.width ?? '100%';
            const fractionalPart = count % 1;
            const fractionalWidth =
                typeof width === 'number'
                    ? width * fractionalPart
                    : `calc(${width} * ${fractionalPart})`;
            currentStyle = { ...currentStyle, width: fractionalWidth };
        }

        /* Skeleton element */
        const skeletonSpan = (
            <span className={className} style={currentStyle} key={c}>
                &zwnj;
            </span>
        );

        if (inline) {
            skeletons.push(skeletonSpan);
        } else {
            skeletons.push(
                <React.Fragment key={c}>
                    {skeletonSpan}
                    <br />
                </React.Fragment>,
            );
        }
    }

    return <span className={containerClassName}>{skeletons}</span>;
}

export default Skeleton;
