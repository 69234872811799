import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import {
    landingTabItem,
    lossRunsTabItem,
    masterDataTabItem,
    settingsTabItem,
} from '../tab/TabItems';
import { AuthContext } from '../provider/AuthContext';
import Home from '../Home';
import LazyComponents from './LazyComponents';
import NavigationTab from '../tab/NavigationTab';
import Postprocessing from '../setting/post-processing/Postprocessing';
import ProtectedRoute from './ProtectedRoute';
import ZealDocLayoutContainer from './ZealDocLayoutContainer';
import route from './routes.json';

function ZealDocRoutes() {
    const currentLocation = useLocation();
    const { user } = useContext(AuthContext);

    const filterLandingTabItems = () => {
        if (user?.isClient) {
            return landingTabItem.filter((item) => {
                return item.url === route['landing']['dataIngestion'];
            });
        }
        return landingTabItem;
    };

    return (
        <Routes location={currentLocation}>
            <Route index={true} caseSensitive={true} element={<Home />} />
            <Route element={<ProtectedRoute />}>
                <Route
                    path={route['dashboard']}
                    caseSensitive={true}
                    index={true}
                    element={
                        <ZealDocLayoutContainer>
                            <LazyComponents.Dashboard />
                        </ZealDocLayoutContainer>
                    }
                />
                {/* <Route
                    path={route['lossRuns']['index']}
                    caseSensitive={true}
                    element={
                        <ZealDocLayoutContainer>
                            <LazyComponents.AllLoss />
                        </ZealDocLayoutContainer>
                    }
                /> */}
                <Route
                    path={route['lossRuns']['index']}
                    caseSensitive={true}
                    element={
                        <ZealDocLayoutContainer>
                            <NavigationTab
                                tab="allLosses"
                                tabItems={lossRunsTabItem}
                                variant="primary"
                            />
                        </ZealDocLayoutContainer>
                    }
                >
                    <Route
                        index={true}
                        caseSensitive={true}
                        element={<LazyComponents.AllLoss />}
                    />
                    <Route
                        path={`${route['lossRuns']['allLosses']}`}
                        caseSensitive={true}
                        element={<LazyComponents.AllLoss />}
                    />
                </Route>
                <Route
                    path={route['landing']['index']}
                    caseSensitive={true}
                    element={
                        <ZealDocLayoutContainer>
                            <NavigationTab
                                tab="landing"
                                tabItems={filterLandingTabItems()}
                                variant="primary"
                            />
                        </ZealDocLayoutContainer>
                    }
                >
                    <Route
                        index={true}
                        caseSensitive={true}
                        element={<LazyComponents.Renamer />}
                    />
                    <Route
                        path={`${route['landing']['renamer']}`}
                        caseSensitive={true}
                        element={<LazyComponents.Renamer />}
                    />
                    <Route
                        path={`${route['landing']['dataIngestion']}`}
                        caseSensitive={true}
                        element={
                            user?.isClient ? (
                                <LazyComponents.DataIngestionClient />
                            ) : (
                                <LazyComponents.DataIngestion />
                            )
                        }
                    />
                    <Route
                        path={`${route['landing']['logs']}`}
                        caseSensitive={true}
                        element={<LazyComponents.Logs />}
                    >
                        <Route
                            index={true}
                            caseSensitive={true}
                            element={<LazyComponents.PreprocessingLogs />}
                        />
                        <Route
                            path={`${route['landing']['preprocessingLogs']}`}
                            caseSensitive={true}
                            element={<LazyComponents.PreprocessingLogs />}
                        />
                        <Route
                            path={`${route['landing']['adfLogs']}`}
                            caseSensitive={true}
                            element={<LazyComponents.AdfLogs />}
                        />
                    </Route>
                </Route>
                <Route
                    path={route['master-data']['index']}
                    caseSensitive={true}
                    element={
                        <ZealDocLayoutContainer>
                            <NavigationTab
                                tab="masterData"
                                tabItems={masterDataTabItem}
                                variant="primary"
                            />
                        </ZealDocLayoutContainer>
                    }
                >
                    <Route
                        index={true}
                        caseSensitive={true}
                        element={<LazyComponents.Account />}
                    />
                    <Route
                        path={`${route['master-data']['account']}`}
                        caseSensitive={true}
                        element={<LazyComponents.Account />}
                    />
                    <Route
                        path={`${route['master-data']['carrier']}`}
                        caseSensitive={true}
                        element={<LazyComponents.Carrier />}
                    />
                    <Route
                        path={`${route['master-data']['lob']}`}
                        caseSensitive={true}
                        element={<LazyComponents.LineOfBusiness />}
                    />
                    <Route
                        path={`${route['master-data']['lkpInsured']}`}
                        caseSensitive={true}
                        element={<LazyComponents.LookupInsured />}
                    />
                    <Route
                        path={`${route['master-data']['policySchedule']}`}
                        caseSensitive={true}
                        element={<LazyComponents.PolicySchedule />}
                    />
                </Route>
                <Route
                    path={route['setting']['index']}
                    caseSensitive={true}
                    element={
                        <ZealDocLayoutContainer>
                            <NavigationTab
                                tab="settings"
                                tabItems={settingsTabItem}
                                variant="primary"
                            />
                        </ZealDocLayoutContainer>
                    }
                >
                    <Route
                        index={true}
                        caseSensitive={true}
                        element={<LazyComponents.Preprocessing />}
                    />
                    <Route
                        path={`${route['setting']['preprocessing']}`}
                        caseSensitive={true}
                        element={<LazyComponents.Preprocessing />}
                    />
                    <Route
                        path={`${route['setting']['postprocessing']}`}
                        caseSensitive={true}
                        element={<Postprocessing />}
                    >
                        <Route
                            index={true}
                            caseSensitive={true}
                            element={<LazyComponents.PdfTransform />}
                        />
                        <Route
                            path={`${route['setting']['pdfTransform']}`}
                            caseSensitive={true}
                            element={<LazyComponents.PdfTransform />}
                        />
                        <Route
                            path={`${route['setting']['excelTransform']}`}
                            caseSensitive={true}
                            element={<LazyComponents.ExcelTransform />}
                        />
                    </Route>
                </Route>
            </Route>
            {/* Catch-All Route for 404 */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
}

export default ZealDocRoutes;
