import 'bootstrap/dist/css/bootstrap.css';
import './styles/common.scss';
import 'rc-slider/assets/index.css';
import App from './App';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import { injectStyle } from 'react-toastify/dist/inject-style';

/* Import css is not working */
/* On demand inject toast style */
injectStyle();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <>
        <App />
        <ToastContainer
            position="bottom-left"
            draggable={false}
            autoClose={6000}
            closeOnClick={false}
        />
    </>,
);
