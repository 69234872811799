import React, { useContext } from 'react';
import { AuthContext } from '../provider/AuthContext';
import classNames from 'classnames';

interface signOutButtonProps {
    customClass?: string;
    loading?: boolean;
}

export const SignOutButton = (props: signOutButtonProps) => {
    const { logout } = useContext(AuthContext);
    const { customClass, loading } = props;

    return (
        <button
            className={classNames(
                'gz-btn-primary-violet',
                customClass,
                loading ? 'loading' : '',
            )}
            onClick={logout}
        >
            Signout
            {loading && <span className="inline-spinner"></span>}
        </button>
    );
};
