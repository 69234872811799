import React, { useContext } from 'react';
import { AuthContext } from '../provider/AuthContext';
import { ReactComponent as GoZealLogo } from '../images/gozeal-logo.svg';
import { HomeMenu } from './HomeMenu';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';

const Header = () => {
    const { isAuthenticated, logoutLoading } = useContext(AuthContext);
    return (
        <div className="px-4 py-3 my-2 text-center">
            <nav id="navbar" className="navbar navbar-expand-lg">
                <div className="container-fluid navbar-container">
                    <GoZealLogo className="d-block mb-4 gz-logo" />
                    <div className="collapse navbar-collapse justify-content-end">
                        {isAuthenticated ? (
                            <>
                                <HomeMenu />
                                <SignOutButton
                                    customClass="w-auto"
                                    loading={logoutLoading}
                                />
                            </>
                        ) : (
                            <SignInButton />
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
