import React from 'react';

interface typeCheckboxProps {
    checked: { id: string; value: boolean };
    onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    disabled?: boolean;
}

const Checkbox = (props: typeCheckboxProps) => {
    const { checked, onChange, disabled } = props;
    return (
        <div className="form-check form-check d-flex align-items-center justify-content-center">
            <input
                className="form-check-input float-none"
                type="checkbox"
                id={`checkbox-${checked.id}`}
                onChange={(e) => {
                    return onChange(e, checked.id);
                }}
                checked={checked.value}
                disabled={disabled}
            ></input>
        </div>
    );
};

export default Checkbox;
