import React, { useContext } from 'react';
import { AuthContext } from '../provider/AuthContext';

export const SignInButton = () => {
    const { login } = useContext(AuthContext);

    return (
        <button className="gz-btn-primary-violet" onClick={login}>
            Signin
        </button>
    );
};
