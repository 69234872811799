import { ReactComponent as GirlYogaIllustration } from './images/girl-yoga-illustration.svg';
import Header from './components/Header';
import React from 'react';

function Home() {
    return (
        <>
            <Header />
            <div className="text-center">
                <GirlYogaIllustration width={280} height={280} />
                <div>Please sign-in with your Microsoft login</div>
            </div>
        </>
    );
}

export default Home;
