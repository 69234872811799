import '../styles/navBar.scss';
import React, { useContext } from 'react';
import { AuthContext } from '../provider/AuthContext';
import routes from '../routes/routes.json';

export const HomeMenu = () => {
    const handleClick = () => {
        window.location.href = routes['dashboard'];
    };
    const { user } = useContext(AuthContext);

    return (
        <nav id="navbar" className="navbar navbar-expand-lg">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <button className="nav-link" onClick={handleClick}>
                        {user?.isClient ? 'Landing' : 'Dashboard'}
                    </button>
                </li>
            </ul>
        </nav>
    );
};
