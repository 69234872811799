import '../styles/primaryNavigationTab.scss';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '../provider/AuthContext';
import UserProfile from '../components/UserProfile';
import _ from 'lodash';
import classnames from 'classnames';
import { tabItemType } from './TabItems';

interface navigationTabProps {
    tab: 'dashboard' | 'allLosses' | 'landing' | 'masterData' | 'settings' | 'logs';
    tabItems: tabItemType[];
    variant: 'primary' | 'secondary';
}

function NavigationTab(props: navigationTabProps) {
    const currentLocation = useLocation();
    const { user, logout } = useContext(AuthContext);

    /* Get classname */
    const getClassName = (order: number, url: string) => {
        switch (order) {
            case 1:
                return `nav-link-order-1 ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            case 2:
                return `nav-link-order-2 gray-out-btn ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            case 3:
                return `nav-link-order-3 gray-out-btn ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            case 4:
                return `nav-link-order-4 gray-out-btn ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
            default:
                return `nav-link-order-1 ${
                    currentLocation.pathname.includes(url) ? 'active' : ''
                }`;
        }
    };

    const tabItems = _.cloneDeep(props.tabItems).sort((a, b) => {
        return a.order - b.order;
    });

    return (
        <div className="zd-navigation-tab-container">
            <div
                className={classnames('nav nav-tabs', 'zd-navigation-tab', props.variant)}
            >
                <div className="zd-navigation-items">
                    {tabItems.map((navItem, index) => {
                        return (
                            <NavLink
                                to={`${navItem.url}`}
                                className={getClassName(navItem.order, navItem.url)}
                                end={index === 0}
                                key={`${navItem.url}`}
                            >
                                {navItem.label}
                            </NavLink>
                        );
                    })}
                </div>
                {props.variant === 'primary' && (
                    <UserProfile
                        accountName={user ? user.firstName : ''}
                        email={user ? user.emailAddress : ''}
                        signOut={logout}
                    />
                )}
            </div>
            <div className="zd-navigation-content gz-scroll">
                <Outlet />
            </div>
        </div>
    );
}

export default NavigationTab;
