export const ROOT_FOLDER = 'mango';
export const ROOT_FOLDER_CLIENT = 'client';
export const MAX_UPLOAD_LIMIT = 20;
export const TERM_YEAR = 20;
export const MASTER_DATA_PAGE_LIMIT = 50;
export const ZIP_DOWNLOAD_FILE_NAME_PREFIX = 'Renamed Loss Runs';
export const MAX_UPLAOD_ERROR = `Maximum of ${MAX_UPLOAD_LIMIT} files are allowed to upload at a time`;
export const SUPPORTED_FILE_FORAMTS = ['.pdf', '.xlsx', '.xls', '.csv'];
export const SUPPORTED_FILE_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/csv',
    'application/vnd.ms-excel',
];
export const HELP_TEXT = `Allowed file formats: ${SUPPORTED_FILE_FORAMTS.join(',')}. Maximum file limit: ${MAX_UPLOAD_LIMIT}.`;
export const FILE_NOT_SUPPORTED_ERROR = `Allowed file formats: ${SUPPORTED_FILE_FORAMTS.join(', ')}`;

/* Radio button values */
export const RADIO_BUTTON_VALUES: { id: 'dashboard' | 'expiration'; label: string }[] = [
    { id: 'dashboard', label: 'Dashboard' },
    { id: 'expiration', label: 'Expiration' },
];

/* Radio button values transform */
export const RADIO_BUTTON_DETAILS_DIALOG: { id: 'view' | 'edit'; label: string }[] = [
    { id: 'view', label: 'View' },
    { id: 'edit', label: 'Edit' },
];

/* Transform pdf edit constants start*/
export const DISABLE_EDIT_COMMON_FIELDS = ['partitionKey', 'rowKey', 'fromDate'];
export const DISABLE_EDIT = ['columnsToBeExtracted', 'metaDataFields'].concat(
    DISABLE_EDIT_COMMON_FIELDS,
);

export const ENABLE_EDIT = [
    'active',
    'commonFields',
    'mergeCells',
    'runningPageChecker',
    'claimCountColumn',
];

export const DEFAULT_BOOL_OPTIONS = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
];

export const COLUMNS_TO_BE_MAPPED = [
    { id: 'ValueMandatory', label: 'Value Mandatory' },
    { id: 'PdfColumnNames', label: 'Pdf Column Names' },
    { id: 'ConditionalColumn', label: 'Conditional Column' },
    { id: 'DependentColumn', label: 'Dependent Column' },
];
/* Transform pdf edit constants end*/

/* All Losses constants start*/
export const LOSS_TYPE_OPTIONS = [
    { value: 'dashboard', label: 'Dashboard' },
    { value: 'expiration', label: 'Expiration' },
];

export const DEFAULT_NO_DATA_TEXT = 'Click on GET button to load Loss Runs';
/* All Losses constants end*/

/* Transform Excel constants start*/
export const EXCEL_SUPPORTED_FILE_FORAMTS = ['.xlsx', '.xls', '.csv'];
export const TRANSFORM_EXCEL_DISABLE_EDIT = [
    'partitionKey',
    'rowKey',
    'metaDataFields',
    'columnsToBeExtracted',
];
export const TRANSFORM_EXCEL_ENABLE_EDIT = [
    'active',
    'carrierName',
    'claimsSheetIndex',
    'headerRowNumber',
];
/* Transform Excel constants end*/
