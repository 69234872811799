import './styles/fonts/fonts.css';
import { AuthContext, AuthProvider } from './provider/AuthContext';
import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Home from './Home';
import MainLoader from './components/MainLoader';
import ZealDocRoutes from './routes/ZealDocRoutes';

const AppContent = () => {
    const { user, isAuthenticated, loading } = useContext(AuthContext);

    if (loading) {
        return <MainLoader />;
    }

    return user && isAuthenticated ? (
        <BrowserRouter>
            <ZealDocRoutes />
        </BrowserRouter>
    ) : (
        <Home />
    );
};

const App = () => {
    return (
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    );
};

export default App;
