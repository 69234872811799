import '../styles/pageLayout.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { ReactComponent as AllLossIcon } from '../images/all-losses-icon.svg';
import { AuthContext } from '../provider/AuthContext';
import { ReactComponent as DashboardIcon } from '../images/dashboard-icon.svg';
import { ReactComponent as GozealZLogo } from '../images/gozeal-z-logo.svg';
import { ReactComponent as LandingIcon } from '../images/landing-icon.svg';
import { ReactComponent as MasterDataIcon } from '../images/master-data.svg';
import { ReactComponent as SettingsIcon } from '../images/settings-icon2.svg';
import routes from '../routes/routes.json';

interface typePageLayoutProps {
    children: React.ReactNode;
}

const ContentLayout = (props: typePageLayoutProps) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.isClient) {
            navigate(routes['landing']['dataIngestion']);
        }
    }, [user, navigate]);

    const landingNavItem = () => {
        return (
            <NavLink to={routes['landing']['renamer']} className={'nav-link gz-nav-btn'}>
                <LandingIcon width="26" height="26" />
                <span>Landing</span>
            </NavLink>
        );
    };

    return (
        <div className="content-layout">
            <div id="navigation-panel">
                <div className="navigation-panel-container">
                    <div className="nav flex-column nav-pills navigation-item-container">
                        <div className="gz-logo flex-column align-items-center">
                            <a className="navbar-brand" href="/">
                                <GozealZLogo fill="#7F7FF0" width={32} height={32} />
                            </a>
                            <span className="pt-1">ZealDoc AI</span>
                        </div>
                        <div className="navigation-item-wrapper gz-scroll">
                            {!user?.isClient && (
                                <>
                                    <NavLink
                                        to={routes['dashboard']}
                                        className="nav-link gz-nav-btn"
                                        end
                                    >
                                        <DashboardIcon width="26" height="26" />
                                        <span>Dashboard</span>
                                    </NavLink>
                                    <NavLink
                                        to={routes['lossRuns']['allLosses']}
                                        className={'nav-link gz-nav-btn'}
                                    >
                                        <AllLossIcon width="26" height="26" />
                                        <span>Loss Runs</span>
                                    </NavLink>
                                    {landingNavItem()}
                                    <NavLink
                                        to={routes['master-data']['account']}
                                        className="nav-link gz-nav-btn gray-out-btn"
                                    >
                                        <MasterDataIcon width="26" height="26" />
                                        <span>Master Data</span>
                                    </NavLink>
                                    <NavLink
                                        to={routes['setting']['preprocessing']}
                                        className={'nav-link gz-nav-btn'}
                                    >
                                        <SettingsIcon width="26" height="26" />
                                        <span>Settings</span>
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-content" id="panel-content">
                {props.children}
            </div>
        </div>
    );
};

export const PageLayout = (props: typePageLayoutProps) => {
    return <ContentLayout>{props.children}</ContentLayout>;
};
