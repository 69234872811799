import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import UserAvatar from './UserAvatar';

interface userProfileProps {
    accountName: string;
    email: string;
    signOut: () => void;
    loading?: boolean;
}

function UserProfile(props: userProfileProps) {
    const { email, accountName, loading, signOut } = props;
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const targetRef = useRef(null);

    const handleShowMenu = (value: boolean) => {
        setShowMenu(value);
    };

    const renderContent = () => {
        return (
            <div className="topbar-profile-menu">
                <UserAvatar name={accountName} email={email} />
                <span className="topbar-menu-item" onClick={signOut}>
                    Sign out
                    {loading && <span className="inline-spinner"></span>}
                </span>
            </div>
        );
    };
    return (
        <>
            <div
                ref={targetRef}
                onClick={() => {
                    return handleShowMenu(!showMenu);
                }}
                className="manage-user-menu-trigger w-auto gap-1"
            >
                <UserAvatar name={accountName} email={email} hideAvatarDetail={true} />
                <div className="topbar-profile-toggle">
                    <span className="topbar-arrow"></span>
                </div>
            </div>
            <Overlay
                target={targetRef.current}
                show={showMenu}
                rootClose={true}
                placement="bottom"
                rootCloseEvent="mousedown"
                onHide={() => {
                    return handleShowMenu(false);
                }}
            >
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => {
                    return <div {...props}>{renderContent()}</div>;
                }}
            </Overlay>
        </>
    );
}

export default UserProfile;
